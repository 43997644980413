<div class="container">
  <div class="title-container">
    <div class="title-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-5 mt-2">
            <h2>Bulk Upload Videos</h2>
          </div>
          <input type="file" (change)="onFileChange($event)" accept=".xlsx, .xls" #fileInput/>
          <button mat-raised-button color="primary" (click)="uploadData(fileInput)" [disabled]="videoResponses.length === 0">
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="errorMessage" class="error">{{ errorMessage }}</div>

  <div *ngIf="isLoading">Loading...</div>

  <div *ngIf="!isLoading && videoResponses.length">
    <div class="table-container table-responsive">
      <table class="table table-hover custom-table equal-width-table">
        <thead>
        <tr class="table-dark">
          <th scope="col">Serial</th>
          <th scope="col">Url</th>
          <th scope="col">Title</th>
          <th scope="col">Description</th>
          <th scope="col">Form</th>
          <th scope="col">Language</th>
          <th scope="col">State</th>
          <th scope="col">Crop</th>
          <th scope="col">Published Date</th>
          <th scope="col">Type</th>
          <th scope="col">Focus Area</th>
          <th scope="col">Tags</th>
          <th scope="col">Creator Name</th>
          <th scope="col">Channel Name</th>
          <th scope="col">Channel Link</th>
        </tr>
        </thead>
        <tbody class="table-group-divider table-divider-color">
        <tr *ngFor="let video of videoResponses; let i = index">
          <td>{{i+1}}</td>
          <td [ngClass]="{'missing-field': missingFields[i]?.includes('URL')}">{{ video.url }}</td>
          <td [ngClass]="{'missing-field': missingFields[i]?.includes('Title')}">{{ video.title }}</td>
          <td>{{ video.description }}</td>
          <td [ngClass]="{'missing-field': missingFields[i]?.includes('Form')}">{{ video.form }}</td>
          <td [ngClass]="{'missing-field': missingFields[i]?.includes('Language')}">{{ languagesMap.get(video.languageIso) }}</td>
          <td>{{ getMappedStates(video.state) }}</td>
          <td>{{ (video.crop || []).join(', ') }}</td>
          <td>{{ video.publishedDate | date:'shortDate' }}</td>
          <td [ngClass]="{'missing-field': missingFields[i]?.includes('Type')}">{{ (video.type || []).join(', ') }}</td>
          <td [ngClass]="{'missing-field': missingFields[i]?.includes('Focus Area')}">{{ (video.focusArea || []).join(', ') }}</td>
          <td [ngClass]="{'missing-field': missingFields[i]?.includes('Tags')}">{{ video.tags }}</td>
          <td>{{ video.creatorName }}</td>
          <td [ngClass]="{'missing-field': missingFields[i]?.includes('Channel Name')}">{{ video.channelName }}</td>
          <td [ngClass]="{'missing-field': missingFields[i]?.includes('Channel Link')}">{{ video.channelUrl }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
