import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import * as uuid from 'uuid';
import {MigrateFOResponseModel} from '../models/migrateFOResponseModel';
import {SuspendFORequestModel} from '../models/suspendFORequestModel';
import {BehaviorSubject, Observable} from 'rxjs';
import {MigrateFOPartialRequestModel} from '../models/migrateFOPartialRequestModel';
import {CreateFieldOfficerModel} from '../models/createFieldOfficerModel';
import {GetVideoRequest} from "../models/GetVideoRequestModel";
import {VideoResponse} from "../models/GetVideoResponseModel";


@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(
    private http: HttpClient
  ) { }

  isLoggedIn = new BehaviorSubject<boolean>(false)

  isLoggedInObs =  this.isLoggedIn.asObservable()

  getFieldOfficersList(pageOffset : number, pageLimit : number){
    console.log("fetching the FO list with page offset ",pageOffset, " and pageLimit ",pageLimit);
    let url = environment.adminServiceUrl + "/FieldOfficer";
    let headers = new HttpHeaders({ requestId: uuid.v4() })
    let parameters = {pageOffset, pageLimit};
    let requestParams = new HttpParams({fromObject : parameters});
    return this.http.get(url, {headers, params : requestParams});
  }


  suspendFO(selectedFO: SuspendFORequestModel){
    let url = environment.adminServiceUrl+"/suspendFieldOfficer"
    let headers = new HttpHeaders({requestId: uuid.v4()})
    return this.http.post<MigrateFOResponseModel>(url, selectedFO, { headers: headers });
  }


  migrateFO(migrateFORequest: any){
    let url = environment.adminServiceUrl+"/migrate/fully"
    let headers = new HttpHeaders({requestId: uuid.v4()})
    return this.http.post<MigrateFOResponseModel>(url, migrateFORequest, { headers: headers });
  }

  migrateFieldOfficerPartial(migrateFieldOfficerPartial : MigrateFOPartialRequestModel){
    console.log(this.migrateFieldOfficerPartial);
    let url = environment.adminServiceUrl+"/migrateFieldOfficerPartial"
    let headers = new HttpHeaders({requestId: uuid.v4()})
    return this.http.post<MigrateFOPartialRequestModel>(url, migrateFieldOfficerPartial, { headers: headers });
  }

  createFieldOfficers(fieldOfficers: CreateFieldOfficerModel[]) {
    let url = environment.adminServiceUrl + "/createFieldOfficer";
    let headers = new HttpHeaders({ requestId: uuid.v4() });
    return this.http.post(url, fieldOfficers, { headers: headers });
  }

  searchFieldOfficer(type : string, searchString : string, pageOffset : number, pageLimit : number ){
    console.log(type);
    let url = environment.adminServiceUrl + "/search";
    let parameters = {type, searchString, pageOffset, pageLimit};
    let requestParameter = new HttpParams({fromObject : parameters});
    let headers = new HttpHeaders({requestId : uuid.v4()});
    return this.http.get(url, {headers, params : requestParameter})
  }

  blockLoyaltyUsers(phoneNumbers: string[], isEarn: boolean){
    let url: string = environment.adminServiceUrl + "/blocked-users";
    let headers = new HttpHeaders({ requestId: uuid.v4() });
    let parameters = {isEarn};
    let requestParams = new HttpParams({fromObject : parameters});
    return this.http.post(url, phoneNumbers, { headers: headers, params : requestParams });
  }

  getBlockedUsersList(pageOffset : number, pageLimit : number, searchString : string){
    console.log("fetching the blocked users list with page offset ",pageOffset, " pageLimit ",pageLimit, " and searchString ",searchString);
    let url = environment.adminServiceUrl + "/blocked-users";
    let headers = new HttpHeaders({ requestId: uuid.v4() })
    let parameters = {pageOffset, pageLimit, searchString};
    let requestParams = new HttpParams({fromObject : parameters});
    return this.http.get(url, {headers, params : requestParams});
  }

  unblockLoyaltyUsers(phoneNumbers: string[], isEarn: boolean) {
    let url: string = environment.adminServiceUrl + "/blocked-users";
    let headers = new HttpHeaders({ requestId: uuid.v4() });
    let parameters = {isEarn};
    let requestParams = new HttpParams({fromObject : parameters});
    return this.http.delete(url, { headers: headers, body: phoneNumbers, params : requestParams });
  }

  getVideoList(getVideoRequest: GetVideoRequest, pageOffset: number, pageLimit: number) {
    const url: string = environment.adminServiceUrl + "/videos";
    const parameters = { pageOffset, pageLimit };
    const headers = new HttpHeaders({ requestId: uuid.v4() });
    const requestParams = new HttpParams({ fromObject: parameters });
    return this.http.post(url, getVideoRequest, { headers: headers, params: requestParams });
  }

  getVideo(videoId: number): Observable<VideoResponse> {
    const url: string = `${environment.adminServiceUrl}/video/${videoId}`;
    const headers = new HttpHeaders({ requestId: uuid.v4() });
    return this.http.get<VideoResponse>(url, { headers: headers });
  }

  updateVideo(videoId: number, videoResponse: VideoResponse){
    const url: string = `${environment.adminServiceUrl}/video/${videoId}`;
    const headers = new HttpHeaders({ requestId: uuid.v4() });
    return this.http.put<void>(url, videoResponse, { headers: headers });
  }

  uploadVideos(videoResponses: VideoResponse[]) {
    const url: string = `${environment.adminServiceUrl}/videos/upload`;
    const headers = new HttpHeaders({ requestId: uuid.v4() });
    return this.http.post<void>(url, videoResponses, { headers: headers });
  }

  deleteVideo(videoId: number) {
    const url: string = `${environment.adminServiceUrl}/video/${videoId}`;
    const headers = new HttpHeaders({ requestId: uuid.v4() });
    return this.http.delete<void>(url, { headers: headers });
  }
}
