import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {VideoResponse} from "../../models/GetVideoResponseModel";
import {ActivatedRoute, Router} from "@angular/router";
import {Utils} from "../../service/utils";
import {ToastrService} from "ngx-toastr";
import {DataService} from "../../service/data.service";
import {catchError, throwError} from "rxjs";

@Component({
  selector: 'app-upload-videos',
  templateUrl: './upload-videos.component.html',
  styleUrls: ['./upload-videos.component.css']
})
export class UploadVideosComponent {

  videoForm: FormGroup;
  isLoading = false;
  statesMap: Map<string, string>;
  languagesMap: Map<string, string>;
  cropsMap: Map<number, string>

  formTypes = ['SHORT', 'LONG'];
  types = ['Entertaining', 'Informative', 'Bayer', 'Promoted', 'Partner'];
  focusAreas = ['Farming', 'Machinery', 'Crop advisory', 'Animal husbandry', 'Finance'];

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private utils: Utils,
              private toastrService: ToastrService,
              private router: Router,
              private dataservice: DataService) {
    this.videoForm = this.fb.group({
      id: [''],
      url: ['', Validators.required],
      title: ['', Validators.required],
      description: [''],
      form: ['', Validators.required],
      languageIso: ['', Validators.required],
      state: [[]],
      crop: [[]],
      type: [[], Validators.required],
      focusArea: [[], Validators.required],
      tags: ['', Validators.required],
      publishedDate: [new Date()],
      creatorName: [''],
      channelName: ['', Validators.required],
      channelUrl: ['', Validators.required]
    });

    this.statesMap = utils.statesMap;
    this.languagesMap = utils.languagesMap
    this.cropsMap = utils.cropsMap
  }

  onSubmit() {
    this.utils.showSpinner();
    if (this.videoForm.valid) {
      const videoData: VideoResponse = this.videoForm.value;
      const videoDataList: VideoResponse[] = [videoData];
      console.log('Video Data:', videoDataList);
      this.dataservice.uploadVideos(videoDataList).pipe(
        catchError(err => {
          console.log(err.statusText);
          this.utils.hideSpinner();
          const responseCode = err.error?.metaData?.responseCode;
          this.toastrService.error(`Videos not uploaded. ${responseCode}`);
          return throwError(err);
        })
      ).subscribe(
        (response: any) => {
          this.utils.hideSpinner();
          this.toastrService.success(`response.message`);
          this.videoForm.reset();
        }
      );
    } else {
      this.utils.hideSpinner();
      this.toastrService.error('Please fill in all required fields.');
    }
  }

  navigateToBulkUpload() {
    this.router.navigate(['/bulk-upload']);
  }
}
