import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class Utils {

  constructor(public router: Router,
    public spinnerService: NgxSpinnerService) {
  }

  public showSpinner() {
    this.spinnerService.show();
  }

  public hideSpinner() {
    this.spinnerService.hide();
  }

  languagesMap = new Map<string, string>([
    ['en', 'English'],
    ['hi', 'Hindi'],
    ['kn', 'Kannada'],
    ['mr', 'Marathi'],
    ['te', 'Telugu'],
    ['gu', 'Gujarati'],
    ['or', 'Odia'],
    ['pa', 'Punjabi'],
    ['bn', 'Bengali'],
    ['ta', 'Tamil'],
  ]);

  statesMap = new Map<string, string>([
    ['AP', 'Andhra Pradesh'],
    ['AR', 'Arunachal Pradesh'],
    ['AS', 'Assam'],
    ['BR', 'Bihar'],
    ['CT', 'Chhattisgarh'],
    ['DL', 'Delhi'],
    ['GA', 'Goa'],
    ['GJ', 'Gujarat'],
    ['HR', 'Haryana'],
    ['HP', 'Himachal Pradesh'],
    ['JK', 'Jammu and Kashmir'],
    ['JH', 'Jharkhand'],
    ['KA', 'Karnataka'],
    ['KL', 'Kerala'],
    ['LA', 'Ladakh'],
    ['LD', 'Lakshadweep'],
    ['MP', 'Madhya Pradesh'],
    ['MH', 'Maharashtra'],
    ['MN', 'Manipur'],
    ['ML', 'Meghalaya'],
    ['MZ', 'Mizoram'],
    ['NL', 'Nagaland'],
    ['OR', 'Odisha'],
    ['PB', 'Punjab'],
    ['RJ', 'Rajasthan'],
    ['TN', 'Tamil Nadu'],
    ['TG', 'Telangana'],
    ['TR', 'Tripura'],
    ['UP', 'Uttar Pradesh'],
    ['UK', 'Uttarakhand'],
    ['WB', 'West Bengal'],
    ['PY', 'Puducherry'],
  ]);

  cropsMap = new Map<number, string>([
    [89, "Amla (nelli kai)"],
    [53, "Apple"],
    [62, "Arecanut(betelnut/supari)"],
    [90, "Ashgourd"],
    [60, "Banana"],
    [84, "Barley"],
    [22, "Beans"],
    [20, "Beetroot"],
    [46, "Bell pepper"],
    [6, "Bengal gram"],
    [16, "Bitter gourd"],
    [47, "Black gram"],
    [70, "Black pepper"],
    [17, "Bottle gourd"],
    [21, "Brinjal"],
    [12, "Cabbage"],
    [85, "Cardamom"],
    [58, "Carom seeds"],
    [23, "Carrot"],
    [24, "Castor"],
    [11, "Cauliflower"],
    [63, "Chikoos(sapota)"],
    [64, "Coconut"],
    [87, "Coffee"],
    [54, "Colocasia"],
    [36, "Coriander"],
    [1, "Corn"],
    [2, "Cotton"],
    [15, "Cucumber"],
    [25, "Cumin seeds"],
    [83, "Custard apple"],
    [26, "Drumstick"],
    [27, "Fennel seeds"],
    [72, "Fenugreek leaves"],
    [82, "Fig"],
    [57, "Finger millet"],
    [37, "Garlic"],
    [38, "Gherkins"],
    [71, "Ginger"],
    [49, "Grapes"],
    [55, "Green capsicum"],
    [14, "Green gram"],
    [39, "Groundnut"],
    [91, "Guava"],
    [73, "Horse gram"],
    [8, "Hotpepper"],
    [29, "Ivy gourd"],
    [86, "Jasmine"],
    [78, "Kinnow"],
    [28, "Lemon"],
    [80, "Lettuce"],
    [92, "Lotus sticks"],
    [61, "Mango"],
    [99, "Mint (pudina)"],
    [65, "Musk-melon"],
    [19, "Mustard"],
    [30, "Okra"],
    [7, "Onion"],
    [81, "Orange"],
    [93, "Papaya"],
    [41, "Pearl millet"],
    [94, "Pear(marasebu)"],
    [42, "Peas"],
    [31, "Pigeon pea"],
    [95, "Pineapple"],
    [96, "Plum"],
    [67, "Pointed gourd"],
    [50, "Pomegranate"],
    [13, "Potato"],
    [59, "Pumpkin"],
    [74, "Pysllium seed"],
    [32, "Radish"],
    [98, "Rajgir"],
    [10, "Rice"],
    [33, "Ridge gourd"],
    [75, "Safflower seeds"],
    [43, "Sesame"],
    [56, "Snake gourd"],
    [40, "Sorghum"],
    [44, "Soyabean"],
    [68, "Spinach"],
    [79, "Strawberry"],
    [51, "Sugarcane"],
    [34, "Sunflower"],
    [77, "Sweet corn"],
    [66, "Sweet lime"],
    [35, "Sweet potato"],
    [76, "Tamarind fruit"],
    [88, "Tea"],
    [97, "Tinda"],
    [69, "Tobacco"],
    [3, "Tomato"],
    [45, "Turmeric"],
    [52, "Watermelon"],
    [4, "Wheat"],
  ]);
}
