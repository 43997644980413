import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoResponse } from '../../models/GetVideoResponseModel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../service/utils';
import { ToastrService } from "ngx-toastr";
import { DataService } from "../../service/data.service";
import {catchError, throwError} from "rxjs";

@Component({
  selector: 'app-edit-videos',
  templateUrl: './edit-videos.component.html',
  styleUrls: ['./edit-videos.component.css']
})
export class EditVideosComponent {
  videoForm: FormGroup;
  videoId: number | null = null;
  videoDetail: VideoResponse | undefined;
  statesMap: Map<string, string>;
  languagesMap: Map<string, string>;
  cropsMap: Map<number, string>;

  formTypes = ['SHORT', 'LONG'];
  types = ['Entertaining', 'Informative', 'Bayer', 'Promoted', 'Partner'];
  focusAreas = ['Farming', 'Machinery', 'Crop advisory', 'Animal husbandry', 'Finance'];

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private utils: Utils,
              private toastrService: ToastrService,
              private router: Router,
              private dataservice: DataService) {

    // Add Validators for mandatory fields
    this.videoForm = this.fb.group({
      id: [''],
      url: ['', Validators.required],
      title: ['', Validators.required],
      description: [''],
      form: ['', Validators.required],
      languageIso: ['', Validators.required],
      state: [[]],
      crop: [[]],
      type: [[], Validators.required],
      focusArea: [[], Validators.required],
      tags: ['', Validators.required],
      publishedDate: [''], // Required field
      creatorName: [''],
      channelName: ['', Validators.required], // Required field
      channelUrl: ['', Validators.required] // Required field
    });

    this.statesMap = utils.statesMap;
    this.languagesMap = utils.languagesMap;
    this.cropsMap = utils.cropsMap;

    this.route.queryParams.subscribe(params => {
      if (params['videoId']) {
        this.videoId = +params['videoId'];
        this.loadVideoDetails(this.videoId);
      }
    });
  }

  loadVideoDetails(videoId: number) {
    this.dataservice.getVideo(videoId).subscribe(
      (video: VideoResponse) => {
        this.videoDetail = video;
        if (this.videoDetail) {
          this.videoForm.patchValue({
            id: this.videoDetail.id,
            url: this.videoDetail.url,
            title: this.videoDetail.title,
            description: this.videoDetail.description,
            form: this.videoDetail.form,
            languageIso: this.videoDetail.languageIso,
            state: this.videoDetail.state,
            crop: this.videoDetail.crop,
            publishedDate: this.videoDetail.publishedDate ? this.formatDate(this.videoDetail.publishedDate) : '',
            type: this.videoDetail.type,
            focusArea: this.videoDetail.focusArea,
            tags: this.videoDetail.tags,
            creatorName: this.videoDetail.creatorName,
            channelName: this.videoDetail.channelName,
            channelUrl: this.videoDetail.channelUrl
          });
        } else {
          console.log('No video found with ID:', videoId);
        }
      },
      error => {
        console.error('Error loading video details:', error);
        this.toastrService.error('Failed to load video details.');
      }
    );
  }

  private formatDate(date: Date): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  onSubmit() {
    this.utils.showSpinner();
    if (this.videoForm.valid) {
      const updatedVideoData: VideoResponse = this.videoForm.value;
      console.log('Updated Video Data:', updatedVideoData);
      this.dataservice.updateVideo(this.videoId!, updatedVideoData).pipe(
        catchError(err => {
          console.log(err.statusText);
          this.utils.hideSpinner();
          const responseCode = err.error?.metaData?.responseCode;
          this.toastrService.error(`Video not updated. ${responseCode}`);
          return throwError(err);
        })
      ).subscribe(
        (response: any) => {
          this.utils.hideSpinner();
          this.toastrService.success(response.message);
          this.router.navigate(['/view-videos']);
        }
      );
    } else {
      this.utils.hideSpinner();
      this.toastrService.error('Please fill in all required fields.');
    }
  }
}
