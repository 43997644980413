import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {VideoResponse} from "../../models/GetVideoResponseModel";
import {Router} from "@angular/router";
import {Utils} from "../../service/utils";
import {PopUpModel} from "../../models/popUpModel";
import {
  ConfirmBlockDialogComponent
} from "../../loyalty-user/blocked-users-list/confirm-block-dialog/confirm-block-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDeleteDialogComponent} from "./confirm-delete-dialog/confirm-delete-dialog.component";
import {DataService} from "../../service/data.service";
import {catchError, throwError} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-view-videos',
  templateUrl: './view-videos.component.html',
  styleUrls: ['./view-videos.component.css']
})
export class ViewVideosComponent {

  videoForm: FormGroup;
  videoResponses: VideoResponse[] = [];
  statesMap: Map<string, string>
  languagesMap: Map<string, string>
  cropsMap: Map<number, string>
  isLoading = false;
  pageOffset: number = 0;
  pageLimit: number = 30;
  displayStyle = "none";
  isDialogOpen: boolean = false;
  currentPage: number = 1;
  totalPages: number = 1;
  elementCount: number = 0;
  dialogRef: MatDialogRef<ConfirmDeleteDialogComponent> | null = null;
  formTypes = ['SHORT', 'LONG'];
  types = ['Entertaining', 'Informative', 'Bayer', 'Promoted', 'Partner'];
  focusAreas = ['Farming', 'Machinery', 'Crop advisory', 'Animal husbandry', 'Finance'];

  popUp: PopUpModel = {
    title: '',
    error: '',
    info: ''
  };

  constructor(private fb: FormBuilder,
              private router: Router,
              private utils: Utils,
              public dialog: MatDialog,
              private dataservice: DataService,
              private toastrService: ToastrService) {
    this.videoForm = this.fb.group({
      forms: [[]],
      languages: [[]],
      states: [[]],
      types: [[]],
      focusAreas: [[]],
      searchString: ['']
    });
    this.statesMap = utils.statesMap;
    this.languagesMap = utils.languagesMap;
    this.cropsMap = utils.cropsMap;
  }

  onSubmit() {
    console.log(this.videoForm.value)
    this.getVideoDetailsList()
  }

  getMappedStates(states: string[]): string {
    return states.map(stateCode => this.statesMap.get(stateCode) || stateCode).join(', ');
  }

  deleteVideo(videoId: number): void {
    console.log('Deleting video with ID:', videoId);
    if (this.isDialogOpen) {
      return;
    }
    this.openConfirmDialogEarn(videoId, 'Do you want to delete the video and its Metadata?', '');
  }

  openConfirmDialogEarn(videoId: number, heading: string, disclaimer: string) {
    this.isDialogOpen = true;
    this.dialogRef = this.dialog.open(ConfirmBlockDialogComponent, {
      panelClass: 'confirm-dialog-container',
      data: {
        heading: heading,
        disclaimer: disclaimer
      },
      disableClose: true
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      if (result) {
        console.log('User confirmed for Blocking Earn');
        this.dataservice.deleteVideo(videoId).pipe(
          catchError(err => {
            console.log(err.statusText);
            const responseCode = err.error?.metaData?.responseCode;
            this.toastrService.error(`Video not deleted. ${responseCode}`);
            return throwError(err);
          })
        ).subscribe(
          (response: any) => {
            this.toastrService.success(response.message);
            this.getVideoDetailsList()
          }
        );
      } else {
        console.log('Confirmation cancelled');
      }
      this.dialogRef = null;
    });
  }

  editVideo(videoId: number) {
    this.router.navigate(['/edit-videos'], {queryParams: {videoId}});
  }

  closePopup() {
    this.displayStyle = "none";
  }

  private getVideoDetailsList() {
    this.utils.showSpinner();
    this.dataservice.getVideoList(this.videoForm.value, this.pageOffset, this.pageLimit)
      .subscribe((videoListDto: any) => {
        this.videoResponses = videoListDto;
        this.videoResponses = this.videoResponses.map((video: any) => ({
          ...video,
          publishedDate: video.publishedDate ? new Date(video.publishedDate) : null
        }));
        this.elementCount = this.videoResponses.length;
        this.utils.hideSpinner();
      });
  }

  previousPage() {
    this.pageOffset = this.pageOffset - this.pageLimit;
    this.currentPage -= 1;
    if(this.pageOffset < 0){
      this.pageOffset = 0;
      this.currentPage += 1;
    }
    this.getVideoDetailsList();
  }

  nextPage() {
    this.pageOffset = this.pageOffset + this.pageLimit;
    this.currentPage += 1;
    this.getVideoDetailsList();
    if(this.elementCount === 0){
      this.previousPage();
    }
  }

}
