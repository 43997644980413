<div class="container">
  <div class="title-container">
    <div class="title-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-5 mt-2">
            <div>
              <h2>Edit Videos</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="videoForm" (ngSubmit)="onSubmit()">
    <div class="form-container">
      <div class="form-grid">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Url</mat-label>
            <input matInput formControlName="url"/>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title"/>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Video Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Form Type</mat-label>
            <mat-select formControlName="form">
              <mat-option *ngFor="let type of formTypes" [value]="type">{{ type }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Language</mat-label>
            <mat-select formControlName="languageIso">
              <mat-option *ngFor="let lang of languagesMap.keys()" [value]="lang">{{ languagesMap.get(lang) }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>State</mat-label>
            <mat-select formControlName="state" multiple>
              <mat-option *ngFor="let state of statesMap.keys()" [value]="state">
                <ng-container *ngIf="statesMap.get(state) as stateName">{{ stateName }}</ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Crop</mat-label>
            <mat-select formControlName="crop" multiple>
              <mat-option *ngFor="let crop of cropsMap.values()" [value]="crop">
                {{ crop }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Published Date</mat-label>
            <input matInput formControlName="publishedDate" type="date"/>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type" multiple>
              <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Focus Area</mat-label>
            <mat-select formControlName="focusArea" multiple>
              <mat-option *ngFor="let area of focusAreas" [value]="area">{{ area }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Tags</mat-label>
            <input matInput formControlName="tags"/>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Creator Name</mat-label>
            <input matInput formControlName="creatorName"/>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Channel Name</mat-label>
            <input matInput formControlName="channelName"/>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Channel Link</mat-label>
            <input matInput formControlName="channelUrl"/>
          </mat-form-field>
        </div>
      </div>
      <button mat-raised-button color="primary" type="submit">Save Changes</button>
    </div>
  </form>
</div>
