<div class="container">
  <div class="title-container">
    <div class="title-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-5 mt-2">
            <div>
              <h2>View/Edit Videos</h2>
            </div>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-2 mt-2 text-right">
            <div class="input-group">
              <form [formGroup]="videoForm">
                <input class="form-control search-input text-center" type="search"
                       formControlName="searchString"
                       placeholder="Search by Title/Author/Channel"
                       aria-label="Search">
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <form [formGroup]="videoForm" (ngSubmit)="onSubmit()">
      <div class="form-container ">
        <div class="form-group">

          <mat-form-field appearance="outline">
            <mat-label>Form Type</mat-label>
            <mat-select formControlName="forms" multiple>
              <mat-option *ngFor="let type of formTypes" [value]="type">{{ type }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Language</mat-label>
            <mat-select formControlName="languages" multiple>
              <mat-option *ngFor="let lang of languagesMap.keys()" [value]="lang">
                <ng-container *ngIf="languagesMap.get(lang) as language">{{ language }}</ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>State</mat-label>
            <mat-select formControlName="states" multiple>
              <mat-option *ngFor="let state of statesMap.keys()" [value]="state">
                <ng-container *ngIf="statesMap.get(state) as stateName">{{ stateName }}</ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select formControlName="types" multiple>
              <mat-option *ngFor="let t of types" [value]="t">{{ t }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Focus Area</mat-label>
            <mat-select formControlName="focusAreas" multiple>
              <mat-option *ngFor="let focus of focusAreas" [value]="focus">{{ focus }}</mat-option>
            </mat-select>
          </mat-form-field>

          <button class="search" type="submit" [disabled]="videoForm.invalid || isLoading">Search</button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isLoading">Loading...</div>

  <div *ngIf="!isLoading && videoResponses.length">
    <div class="table-container table-responsive">
      <table class="table table-hover custom-table equal-width-table">
        <thead>
        <tr class="table-dark">
          <th scope="col">Serial</th>
          <th scope="col">Video Id</th>
          <th scope="col">Url</th>
          <th scope="col">Title</th>
          <th scope="col">Description</th>
          <th scope="col">Form</th>
          <th scope="col">Language</th>
          <th scope="col">State</th>
          <th scope="col">Crop</th>
          <th scope="col">Published Date</th>
          <th scope="col">Type</th>
          <th scope="col">Focus Area</th>
          <th scope="col">Tags</th>
          <th scope="col">Creator Name</th>
          <th scope="col">Channel Name</th>
          <th scope="col">Channel Link</th>
          <th scope="col">Edit</th>
          <th scope="col">Delete</th>
        </tr>
        </thead>
        <tbody class="table-group-divider table-divider-color">
        <tr *ngFor="let video of videoResponses; let i = index">
          <td>{{ i+1 }}</td>
          <td>{{ video.id }}</td>
          <td>{{ video.url }}</td>
          <td>{{ video.title }}</td>
          <td>{{ video.description }}</td>
          <td>{{ video.form }}</td>
          <td>{{ languagesMap.get(video.languageIso) }}</td>
          <td>{{ getMappedStates(video.state) }}</td>
          <td>{{ video.crop }}</td>
          <td>{{ video.publishedDate | date: 'shortDate'}}</td>
          <td>{{ video.type.join(', ') }}</td>
          <td>{{ video.focusArea.join(', ') }}</td>
          <td>{{ video.tags }}</td>
          <td>{{ video.creatorName }}</td>
          <td>{{ video.channelName }}</td>
          <td>{{ video.channelUrl }}</td>
          <td>
            <button mat-icon-button *ngIf="video.id !== null" (click)="editVideo(video.id)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
          <td>
            <button mat-icon-button *ngIf="video.id !== null" (click)="deleteVideo(video.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!isLoading && !videoResponses.length">
    <p>No videos found.</p>
  </div>

  <div class="pagination-container" *ngIf="videoResponses && videoResponses.length > 0">
    <div class="arrow-buttons">
      <div class="arrow-button" *ngIf="pageOffset >= pageLimit" (click)="previousPage()">
        <i class="fas fa-arrow-circle-left"></i>
      </div>
      <div class="arrow-button" *ngIf="elementCount === pageLimit" (click)="nextPage()">
        <i class="fas fa-arrow-circle-right"></i>
      </div>
    </div>
  </div>

</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ popUp.title }}</h4>
      </div>
      <div class="modal-body">
        <p *ngIf="popUp.error">{{ popUp.error }}</p>
        <p *ngIf="popUp.info">{{ popUp.info }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
